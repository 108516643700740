@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply font-sans;
  @apply text-gray-800;
}

@keyframes spinning-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.md-content {
  h2 {
    @apply block;
    @apply border-b-2;
    @apply mt-4;
    @apply pb-1;
    @apply mb-2;
    @apply text-xl;
    @apply text-gray-900;
    @apply font-bold;
  }

  h3 {
    @apply mt-2;
    @apply mb-1;
    @apply text-lg;
    @apply text-gray-700;
    @apply font-bold;
  }

  a {
    @apply underline;
    @apply text-blue-600;

    &:hover {
      @apply text-blue-800;
    }
  }

  p {
    @apply pt-2;
  }

  img {
    @apply w-auto;
    @apply h-auto;
    @apply max-w-full;
    @apply border-2;
    @apply my-2;
    @apply rounded;
    @apply overflow-hidden;
  }

  table {
    @apply table-auto;
    @apply rounded;
    @apply overflow-hidden;
    @apply mt-4;

    td,
    th {
      @apply py-2;
      @apply px-4;
    }

    thead {
      tr {
        @apply bg-gray-200;
      }
    }

    tbody {
      @apply border-2;

      td {
        @apply border;
      }

      tr:nth-child(even) {
        @apply bg-gray-100;
      }
    }
  }

  ul,
  ol {
    @apply list-outside;
    @apply pt-2;
    @apply ml-4;
  }

  ul {
    @apply list-disc;
    @apply pt-0;
    @apply pb-2;
    @apply pl-4;

    li {
      @apply pl-2;
    }    
  }

  ol {
    @apply list-decimal;
    @apply pl-2;

    li {
      @apply pl-4;
      @apply py-1;
    }

    &~ p + ul{
      @apply pt-2;
      @apply ml-16;

      li{
        @apply py-0.5;
      }
      
      strong{
        @apply underline;
      }

      &+ p{
        @apply pl-10;
        @apply py-0;
        @apply my-0;
      }
    }
  }  

  ol ~ p {
    @apply pl-10;
    @apply py-0;
    @apply my-0;

    img{
      @apply my-3;
      @apply pb-0;
    }


  }

  mark{
    background-color: rgba(37, 99, 235, 0.3);
    @apply px-1;
    @apply mx-1;
    @apply rounded;
  }

  hr{
    @apply my-5;
  }
}
